<template>
  <div id="applyforrefund">
    <div class="card box-p-20 box-m-t-12">
      <goodsV2
        :img_url="refundInfo.cover"
        :description="refundInfo.title"
        :unit_price="refundInfo.goods_amount"
        :num="refundInfo.total"
        total_price=""
        :spec-text="spec_text"
      >
      </goodsV2>
    </div>
    <div class="card box-p-20 box-m-t-12">
      <div class="card_title">退款信息</div>
      <div class="refund-number">
        <div class="label">退款数量</div>
        <div class="values">
          <nut-textinput
            v-if="typeof refundInfo.number == 'undefined'"
            v-model="refund_number"
            placeholder="请输入退款数量"
            :clearBtn="true"
            :disabled="false"
            type="number"
            :min="1"
            :max="refundInfo.total"
            style="width: 250px; text-align: right; margin-top: 10px"
          />
          <template v-else>退款{{ refundInfo.number }}件</template>
        </div>
      </div>
      <cellV2
        class="box-m-t-20"
        leftText="退款原因"
        :rightText="reasonChecked"
        :rightIcon="require('@/static/image/icons/arrowRight.png')"
        @onRight="refundInfo.id == 0 ? (isVisible = true) : ''"
      ></cellV2>
      <div class="refund-number" v-if="reasonChecked == '其它原因'">
        <div class="label">其它原因</div>
        <div class="values">
          <nut-textinput
            v-model="other_reason"
            placeholder="请输入您的退款原因"
            :clearBtn="true"
            :disabled="false"
            type="text"
            style="width: 250px; margin-top: 10px"
          />
        </div>
      </div>
      <!-- <cellV2
        class="box-m-t-20"
        :type="1"
        leftText="退款金额"
        :rightText="'¥' + refundInfo.money"
      ></cellV2> -->
    </div>
    <div class="card box-p-15 box-m-t-12">
      <div class="card_title">详细原因</div>
      <div class="card card_content box-p-14 box-m-t-16">
        <nut-textbox
          v-if="refundInfo.id == 0"
          :txt-areaH="5"
          :place-text="'请补充详细维权原因，有助于更好的处理问题'"
          :switch-max="true"
          :disabled="refundInfo.id != 0"
          :max-num="50"
          v-model="refundInfo.content"
        >
        </nut-textbox>
        <div v-else class="box-p-10" style="font-size: 14px">
          {{ refundInfo.content }}
        </div>
        <div class="box-m-t-10">
          <uploadImgV1
            :max="5"
            :width="60"
            :height="60"
            :margin="2"
            :disabled="refundInfo.id != 0"
            :imgList.sync="refundInfo.refund_images"
            apiUrl="https://community-mall.mengjingloulan.com/file-upload"
            :autoUpload="true"
            @clickImg="previewImg"
          ></uploadImgV1>
          <!-- apiUrl="http://community-mall.rsck.group/file-upload" -->
        </div>
      </div>
    </div>
    <div
      class="card box-p-15 box-m-t-12"
      v-if="refundInfo.id > 0 && refundInfo.status_name"
    >
      <div class="card_title">退款进度</div>
      <div class="card_context box-m-t-8" style="color: red">
        {{ refundInfo.status_name }}
      </div>
    </div>
    <div
      class="card box-p-15 box-m-t-12"
      v-if="refundInfo.id > 0 && refundInfo.cant_return_reason"
    >
      <div class="card_title">不退款原因</div>
      <div class="card_context box-m-t-8" style="color: red">
        {{ refundInfo.cant_return_reason }}
      </div>
    </div>

    <div class="btn l-pa" v-if="refundInfo.id == 0">
      <button v-debounce="['click', refundApply, 500]">提交</button>
    </div>
    <nut-actionsheet
      :is-visible="isVisible"
      :is-lock-bg-scroll="true"
      :menu-items="refundInfo.reason"
      cancelTxt="取消"
      :choose-tag-value="reasonChecked"
      option-tag="title"
      @close="isVisible = false"
      @choose="chooseItem"
    ></nut-actionsheet>
  </div>
</template>

<script>
import goodsV2 from "@T/community/goods/goodsV2";
import cellV2 from "@T/community/cell/cellV2.vue";
import uploadImgV1 from "@T/community/uploadImg/uploadImgV1";

export default {
  name: "applyforrefund",
  components: {
    goodsV2,
    cellV2,
    uploadImgV1,
  },
  data() {
    return {
      isPost: false,
      isVisible: false, //是否展示选择
      order_id: 0, //订单id
      goods_id: 0, //商品id
      spec_ids: "", //规格ID
      sepc_text: "", //规格文字
      refund_number: 0,
      other_reason: "",
      refundInfo: {
        id: 0, //大于零提交过退款
        content: "", //退款详细原因
        order_id: 0, //订单id
        return_exchange: 0, //是否可以退款 0可退换 1不可退换
        cover: "", //商品图片
        title: "", //商品名称
        goods_amount: "", //商品价格
        total: 800, //数量
        refund_images: [], //退款图片
        reason: [], //退款原因
        status_name: "", //退款进度
        cant_return_reason: "", //不退还原因
      },
    };
  },
  watch: {
    refund_number(newV, oldV) {
      if (newV > this.refundInfo.total) {
        this.refund_number = this.refundInfo.total;
      }
    },
  },
  created() {
    this.order_id = this.$route?.query?.order_id || 0;
    this.goods_id = this.$route?.query?.goods_id || 0;
    this.spec_ids = this.$route?.query?.spec_ids || "";
    this.spec_text = this.$route?.query?.sepc_text || "";

    document.title = "申请退款";
    this.getRefundInfo();
  },
  computed: {
    reasonChecked() {
      let item = this.refundInfo.reason.find((val) => val.selected == 1);
      if (item) {
        return item?.title;
      }
      return "请选择退款原因";
    },
  },
  methods: {
    imgMsg(e) {
      console.log(e);
    },
    async getRefundInfo() {
      try {
        let query = await this.$api.community.order.getRefundInfo({
          order_id: this.order_id,
          goods_id: this.goods_id,
          spec_ids: this.spec_ids,
        });
        this.refundInfo = query.data;
        let reasonList = query.data.reason;
        reasonList.push({
          title: "其它原因",
          selected: 0,
        });

        this.refund_number = query.data.total;
      } catch (error) {
        let self = this;
        this.$dialog({
          title: "温馨提示",
          content: error.message,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          noCancelBtn: true,
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn() {
            self.$dialog.closed();
            self.$platform.wxsdk.wxRoute({
              type: "navigateBack",
            });
          },
        });
      }
    },
    async refundApply() {
      if (this.isPost) {
        return;
      }
      this.isPost = true;
      try {
        if (this.refundInfo.return_exchange == 1) {
          throw new Error(this.refundInfo.cant_return_reason);
        }
        if (this.reasonChecked == "请选择退款原因") {
          throw new Error(this.reasonChecked);
        }

        if (this.reasonChecked == "其它原因") {
          if (!this.other_reason) {
            throw new Error("请输入您的退款原因");
          }
        }

        if (!this.refund_number) {
          throw new Error("请输入退款数量");
        }
        let params = {
          id: this.refundInfo.id,
          order_id: this.order_id,
          goods_id: this.goods_id,
          spec_ids: this.spec_ids,
          refund_images: this.refundInfo.refund_images
            .map((val) => val.id)
            .join(","),
          reason: this.refundInfo.reason.find((val) => val.selected == 1).title,
          content: this.refundInfo.content,
          number: this.refund_number,
          other_reason: this.other_reason,
        };
        let self = this;
        this.$api.community.order.refundApply(params).then(() => {
          self.$dialog({
            title: "温馨提示",
            content: "退款申请提交成功",
            closeOnClickModal: false, //点击蒙层是否关闭对话框
            noCancelBtn: true,
            closeBtn: false, //显式右上角关闭按钮
            onOkBtn() {
              self.$dialog.closed();
              let options = {
                order_id: self.order_id,
                goods_id: self.goods_id,
              };
              options = encodeURIComponent(JSON.stringify(options));
              self.$platform.wxsdk.wxRoute({
                type: "redirectTo",
                url: "/web/community/order-info?query=" + options,
              });
            },
          });
          self.isPost = false;
        });
      } catch (error) {
        let self = this;
        this.$dialog({
          title: "温馨提示",
          content: error.message || error,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          noCancelBtn: true,
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn() {
            self.$dialog.closed();
            // let options = {
            //   order_id: self.order_id,
            //   goods_id: self.goods_id,
            // }
            // options = encodeURIComponent(JSON.stringify(options))
            // self.$platform.wxsdk.wxRoute({
            //   type: 'redirectTo',
            //   url: '/web/community/order-info?query=' + options,
            // })
          },
        });
        this.isPost = false;
      }
    },
    chooseItem(e) {
      this.refundInfo.reason = this.refundInfo.reason.map((val) => {
        if (val.title == e.title) {
          val.selected = 1;
        } else {
          val.selected = 0;
        }
        return val;
      });
    },
    previewImg(e) {
      let item = null;

      let index = 0;
      this.refundInfo.refund_images.map((val, idx) => {
        if (val.id == e.msg) {
          item = val;
          index = idx;
        }
      });
      console.log(item);
      this.$previewImg({
        imgUrl: item.path,
        showCancel: this.refundInfo.id == 0,
        success: () => {},
        fail: () => {
          let list = this.refundInfo.refund_images;
          list.splice(index, 1);
          this.refundInfo.refund_images = list;
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
#applyforrefund {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
}

@include b(card) {
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  @include e(title) {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  @include e(content) {
    background: #f6f6f6;
    border-radius: 8px;
  }
  @include e(context) {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #fff;
  }
}

/deep/ .nut-textbox > .txt-area > textarea {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;

  &::placeholder {
    color: #a2a2a2;
  }
}

@include b(btn) {
  bottom: 28px;
  width: 100%;
  display: flex;
  & > button {
    margin: 0 auto;
    width: 345px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    border: none;
  }
}

.refund-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .label {
    color: #a2a2a2;
    font-size: 14px;
  }

  .values {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #a2a2a2;
    font-size: 14px;
  }
}
</style>
